import { OrderStatuses, orderStatusesOptions } from '@root/components/RefundOrderModal/types';
import { useMemo, useState } from 'react';

interface IRefundParams {
  message: string | undefined;
  status: string | undefined;
}

export const useRefundOrderModal = ({ status }: IRefundParams) => {
  const [statusOrder, setStatusOrder] = useState<OrderStatuses | string>('');
  const [reason, setReason] = useState<string>('');

  const filteredStatusOptions = useMemo(
    () => orderStatusesOptions.filter((option) => option.value !== status),
    [orderStatusesOptions]
  );

  const clearFields = () => {
    setStatusOrder(''), setReason('');
  };

  const state = {
    statusOrder,
    reason,
    filteredStatusOptions,
  };

  const handlers = {
    setStatusOrder,
    setReason,
    clearFields,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
