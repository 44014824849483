import { Box, Button, Grid, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from '@material-ui/lab';
import { FormikProps, useFormik } from 'formik';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import addRequiredPlanToCouponUsageRule, {
  IRequiredPlanRequestBody,
} from '../../services/api/requests/addRequiredPlanToCouponUsageRule';
import addRequiredServiceToCouponUsageRule from '../../services/api/requests/addRequiredServiceToCouponUsageRule';
import { INormalizedCouponUsageRule } from '../../services/api/requests/getCouponUsageRules';
import { selectPlansOptionsValueID, selectServicesOptions } from '../../store/entities/selectors';
import { SELECT_OPTION, SUBSCRIPTION_STATUS } from '../../utils/commonTypes';
import { filterAlreadyAddedRequiredPlans } from '../../utils/helpers';
import EditStatusButton from '../EditStatusButton';
import { useStandardFormStyles } from '../FormBase';
import InputSelect from '../InputSelect';
import { shouldHaveStatusOptions } from '../PurchaseRules';

const CouponUsageRulesControl: FC<{
  usageRuleId: number | null;
  usageRules: INormalizedCouponUsageRule[];
  fetchUsageRules: () => void;
  createFirstRule?: (data: IRequiredPlanRequestBody) => void;
}> = ({ usageRuleId, usageRules, fetchUsageRules, createFirstRule }) => {
  const plans = useSelector(selectPlansOptionsValueID);
  const services = useSelector(selectServicesOptions);
  const classes = useStandardFormStyles();
  const [showCreateRulePanel, togglePanel] = useState<boolean>(false);
  const [shouldHaveStatus, setShouldHaveStatus] = useState<SUBSCRIPTION_STATUS | null>(null);
  const [serviceId, setServiceId] = useState<number | undefined>(undefined);

  const fk: FormikProps<{ label: string; value: number }> = useFormik({
    initialValues: { label: '', value: 0 },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values, handlers) => {
      try {
        const data = { planId: values.value, shouldHaveStatus, serviceId };

        if (usageRuleId) {
          values.value && (await addRequiredPlanToCouponUsageRule({ id: usageRuleId, data }));
          serviceId && (await addRequiredServiceToCouponUsageRule({ id: usageRuleId, serviceId }));
          setServiceId(undefined);
          handlers.setFieldValue('value', 0)
        } else {
          !!createFirstRule && (await createFirstRule(data));
        }

        fetchUsageRules();
        togglePanel(false);
      } catch (e) {
        console.warn('create required plan of coupon usage rules error', e);
      }
    },
  });

  return (
    <Box className={classes.purchaseRuleControlContainer}>
      {showCreateRulePanel && (
        <Box flexDirection="column" className={classes.purchaseRuleControlContainer}>
          <Box className={classes.purchaseRuleControlsWrapper}>
            <InputSelect
              id="requiredPlanId"
              label={'Required Plan'}
              value={fk.values.value}
              onChange={(e) => fk.setFieldValue('value', e.target.value)}
              options={filterAlreadyAddedRequiredPlans(plans, usageRules)}
              onBlur={fk.handleBlur}
              style={{ height: '36px', marginRight: '10px' }}
            />
            <EditStatusButton
              status={shouldHaveStatus || SELECT_OPTION.ANY}
              options={shouldHaveStatusOptions as SUBSCRIPTION_STATUS & SELECT_OPTION}
              onChange={(status: SUBSCRIPTION_STATUS & SELECT_OPTION) => {
                setShouldHaveStatus(status === SELECT_OPTION.ANY ? null : status);
              }}
            />
          </Box>
          <Box marginTop={2} className={classes.purchaseRuleControlsWrapper}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="permissionIds"
                options={services}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Choose a service" variant="outlined" fullWidth />
                )}
                onChange={(e, selected) => setServiceId(selected?.value || undefined)}
              />
            </Grid>
          </Box>
        </Box>
      )}

      {usageRuleId && (
        <Box className={classes.flexEnd}>
          <Button
            type="button"
            disabled={showCreateRulePanel && !fk.values.value && !serviceId}
            variant={showCreateRulePanel ? 'contained' : 'outlined'}
            color="primary"
            startIcon={showCreateRulePanel ? null : <AddCircleIcon />}
            onClick={(e) =>
              // @ts-ignore
              showCreateRulePanel ? fk.handleSubmit(fk.values) : togglePanel(!showCreateRulePanel)
            }
          >
            {showCreateRulePanel ? 'save' : 'add rule'}
          </Button>
        </Box>
      )}

      {!usageRuleId && (
        <Button
          type="button"
          disabled={showCreateRulePanel && fk.values.value === 0 && !serviceId}
          variant={showCreateRulePanel ? 'contained' : 'outlined'}
          color="primary"
          fullWidth={!showCreateRulePanel}
          startIcon={showCreateRulePanel ? null : <AddCircleIcon />}
          onClick={(e) =>
            // @ts-ignore
            showCreateRulePanel ? fk.handleSubmit(fk.values) : togglePanel(!showCreateRulePanel)
          }
        >
          {showCreateRulePanel ? 'save' : 'add rule'}
        </Button>
      )}
    </Box>
  );
};

export default CouponUsageRulesControl;
