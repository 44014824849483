export enum OrderStatuses {
  REFUNDED = 'Refunded',
  ON_HOLD = 'On hold',
  CANCELED = 'Canceled',
  BLOCKCHAIN_PENDING = 'Blockchain Pending',
  WAITING_FOR_PAYMENTS = 'Waiting for payment',
  PARTIALLY_PAID = 'Partially paid',
}

export const orderStatusesOptions = Object.values(OrderStatuses).map((option) => ({
  label: option,
	value: option,
}));
