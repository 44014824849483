import { IBodyData } from '@root/pages/DeclineOrders/types';
import { OrderSortDirection, StatusFilter, TypeFilter } from '@root/pages/Orders/types';
import { createQueryParams } from '@root/utils/helpers';
import { AxiosResponse } from 'axios';
import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { IMeta } from '@root/services/api/types';

interface IOrderItem {
  id: number;
  action: 'UPGRADE' | 'RENEW' | 'INITIAL';
  exigoOrderId: number;
  amount: number;
  product: string;
  level: string;
  isBundle: boolean;
}

export interface IOrder {
  amount: number;
  amountFee: number;
  amountVat: number;
  createdAt: string;
  id: number;
  items: IOrderItem[];
  membership: string;
  paymentMethod: string;
  product: string;
  rebillyTransactionID: string;
  status: string;
  verified: boolean;
  type: string;
  user: { id: number; username: string };
  message: string;
  transactions: ITransactions[];
  purchaseType: 'partial' | 'regular';
}

export interface ITransactions {
  id: number;
  status: string;
  source: string;
  amount: number;
  amountFee: number;
  amountVat: number;
  fullAmount: number;
  rebillyTransactionId: string;
  paymentMethod: string;
  amountCurrency: string;
  usedCurrency: string;
  rebillyPaymentInstrumentId: string;
  rebillyGatewayAccountId: string;
  rebillyPaymentMethod: string;
  companyName: string;
  flowId: number;
  createdAt: string;
  approvalUrl: string;
}

export interface IGetOrdersParams {
  page: number;
  limit: number;
  sortDirection?: OrderSortDirection;
  adminSortKey?: string;
  typeFilter?: TypeFilter;
  statusFilter?: StatusFilter;
  paymentMethodFilter?: string;
  toDate?: string | Date;
  fromDate?: string | Date;
  searchByUser?: string;
  searchByProduct?: string;
}

export type GetFailedOrdersParams = Pick<IGetOrdersParams, 'page' | 'limit' | 'sortDirection'>;

export interface IOrderListResponse {
  data: {
    items: IOrder[];
    meta: IMeta;
  };
}

export interface IDeclineOrderResponse {
  data: IOrder;
}

export interface IRefundOrder {
  id: number;
  description: string;
}

export interface IUpdateOrderStatus {
  id: number;
  newStatus: string;
  reason: string;
}

export const markAsRefunded = (data: IRefundOrder) => {
  return apiClient.put(ApiUrls.refundOrder.replace('id', String(data.id)), {
    description: data.description,
  });
};

export const updateOrderStatus = (data: IUpdateOrderStatus) => {
  return apiClient.put(ApiUrls.updateStatus.replace('id', String(data.id)), {
    status: data.newStatus,
  });
};

export const getOrders = (params: IGetOrdersParams): Promise<AxiosResponse<IOrderListResponse>> => {
  const query = createQueryParams<IGetOrdersParams>(params);
  return apiClient.get<IOrderListResponse>(ApiUrls.orderList + `?${query}`);
};

export const getOrder = (id: string): Promise<AxiosResponse<IDeclineOrderResponse>> => {
  return apiClient.get<IDeclineOrderResponse>(ApiUrls.order.replace('{id}', `${id}`));
};

export const getFailedList = async (
  params: GetFailedOrdersParams
): Promise<AxiosResponse<IOrderListResponse> | undefined> => {
  const query = createQueryParams<GetFailedOrdersParams>(params);
  return apiClient.get<IOrderListResponse>(ApiUrls.failedList + `?${query}`);
};

export const uploadFailedOrderList = async (
  body: IBodyData
): Promise<AxiosResponse<IOrderListResponse> | undefined> => {
  return apiClient.post(ApiUrls.uploadFailedOrder, body);
};
